import React, { PropsWithChildren } from "react";
import { Minus, Plus } from "lucide-react";

const FaqToggle = (props: PropsWithChildren<{ q: string }>) => {
  return (
    <details className="group border-b border-b-gray-700 last:border-b-0 pb-4">
      <summary className="cursor-pointer text-gray-100 flex items-center gap-3">
        <h3 className="font-bold text-xl">
          {props.q}
        </h3>
        <div className="group-open:hidden">
          <Plus />
        </div>
        <div className="hidden group-open:block">
          <Minus />
        </div>
      </summary>
      {props.children}
    </details>
  );
};

export default FaqToggle;
