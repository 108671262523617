import React, { ReactElement } from "react";
import { maxTrialCredits, useUser } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  ArrowDown,
  ArrowRight,
  Check,
  ChevronRight,
  FileQuestion,
  Frown,
  HelpCircle,
  Lightbulb,
  ListTree,
  RectangleHorizontal,
  Wand2
} from "lucide-react";
import Tooltip from "@/components/misc/Tooltip";
import { json, LoaderFunctionArgs } from "@remix-run/cloudflare";
// @ts-ignore
import { existsConfig } from "@/lib/config.server";
import { Link, useLoaderData } from "@remix-run/react";
import { getPlans } from "@/lib/misc.server";
import FaqToggle from "@/components/misc/FAQToggle";

export const links = () => {
  return [
    {
      rel: "canonical",
      href: "https://rank-hub.com"
    }
  ];
};


export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  return json({
    DEV: existsConfig(context, "DEV"),
    plans: getPlans()
  });
};

const Review = (props: { imageUrl: string, textContent: ReactElement }) => {

};

const Index = () => {
  const user = useUser();
  const { plans } = useLoaderData<typeof loader>();

  return (
    <div className="bg-gray-900 text-gray-400 overflow-hidden" id="home">
      <div className="pt-32 px-8 pb-16 md:px-24 relative">
        {/*<div className="absolute inset-0 ">*/}
        <div className="absolute inset-0 hero-bg" />
        {/*<div className="absolute inset-0 bg-gradient-to-bl from-white to-black mix-blend-saturation"/>*/}
        {/*</div>*/}


        <div className="mx-auto max-w-5xl pb-8 relative">
          {/*hero*/}
          <div className="flex flex-col items-center text-center">
            <h2 className="font-bold text-primary-500 text-lg md:text-2xl">
              SEO Beyond the AI-Hype
            </h2>
            <h1 className="mt-2 max-w-4xl font-black text-white text-4xl md:text-6xl !leading-[1.2]">
              Are you tired of the SEO guessing game?
            </h1>

            <p className="mt-4 text-white text-lg md:text-2xl !leading-[1.5] max-w-[40rem] font-semibold">
              {/*Rank-Hub uses live data from Google for every step and*/}
              {/*reorganizes with AI. This guarantees you only get the most reliable results with one click.*/}
              Rank-Hub divides SEO into a multi-step workflow that gives you precise controls and intelligent defaults.
            </p>

            <div className="mt-10 md:mt-16 flex flex-col md:flex-row gap-2 md:gap-4 items-center">
              {user ?
                <Button to="/app">
                  My Dashboard
                  <div className="animate-buttonicon">
                    <ChevronRight />
                  </div>
                </Button>
                :
                <div className="flex flex-col items-center">
                  <Button to="/signup">
                    Get {maxTrialCredits} credits for free
                    <div className="animate-buttonicon">
                      <ChevronRight />
                    </div>
                  </Button>
                  <p className="text-gray-300 mt-2 italic text-sm">
                    {/* TODO: Split test below vs only "no credit card required" */}
                    no credit card required &middot; easy to learn
                  </p>
                </div>
              }

              {/*
              {!user &&
                <a href="/blog"
                   className="transition-all font-sans hover:bg-primary-500/20 text-primary-500 font-bold px-4 py-2 rounded-md disabled:text-gray-400 flex items-center gap-3">
                  Read Guides
                  <ChevronRight className="" />
                </a>
              }
*/}
            </div>
          </div>
        </div>

        <div className="mt-2 px-4 flex flex-col sm:flex-row gap-6 md:gap-12 items-start justify-center py-8 relative">
          <div className="sm:w-0 flex-grow flex justify-end">
            <div>
              <h2 className="font-bold text-2xl text-gray-50">Other Tools</h2>
              <div className="flex flex-col gap-3 mt-2 text-lg text-gray-200 sm:max-w-[30rem]">
                <div className="flex items-center gap-5">
                  <FileQuestion size="22" className="flex-shrink-0 text-red-500" />
                  <p>Topic/content recommendations solely made by AI with no real data</p>
                </div>
                <div className="flex items-center gap-5">
                  <RectangleHorizontal size="22" className="flex-shrink-0 text-red-500" />
                  <p>AI writing is a click-and-done gimmicky wrapper for ChatGPT</p>
                </div>
                <div className="flex items-center gap-5">
                  <Frown size="22" className="flex-shrink-0 text-red-500" />
                  <p>No strategic guidance or recommendations providing you with specific steps</p>
                </div>
                {/*
                <div className="flex items-center gap-3">
                  <XCircle size="16" className="flex-shrink-0"/>
                  <p>Not knowing how to fill thousand let alone two thousand word posts</p>
                </div>
*/}
              </div>
            </div>
          </div>
          <div className="text-gray-300 self-center hidden sm:block">
            <ArrowRight size="24" />
          </div>
          <div className="text-gray-300 self-center sm:hidden">
            <ArrowDown size="24" />
          </div>
          <div className="sm:w-0 flex-grow">
            <div>
              <h2 className="font-bold text-2xl text-gray-50">Rank-Hub</h2>
              <div className="flex flex-col gap-3 mt-2 text-lg text-gray-200 sm:max-w-[30rem]">
                <div className="flex items-center gap-5">
                  <Lightbulb size="22" className="flex-shrink-0 text-green-500" />
                  <p>Topic clusters created based on what Google wants you to write about (live SERP data)</p>
                </div>
                <div className="flex items-center gap-5">
                  <ListTree size="22" className="flex-shrink-0 text-green-500" />
                  <p>Sophisticated, section-based AI writing with 100% control and virtually no hallucinations</p>
                </div>
                <div className="flex items-center gap-5">
                  <Wand2 size="22" className="flex-shrink-0 text-green-500" />
                  <p>Weekly set of best effort vs. effect tasks based on your Google Search Console data</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Review Fernando */}
      <div className="bg-gray-800 px-8">
        <div
          className="flex flex-col md:flex-row items-center max-w-[70rem] w-full mx-auto py-16 md:py-24 gap-10 md:gap-24">
          <img src="/testimonial-pictures/fernando2.jpg" alt="Fernando from Kansei" width="400" height="400"
               className="rounded-full w-32 h-32" />
          <div className="">
            <div className="text-xl md:text-2xl text-gray-200 font-semibold tracking-wide leading-[1.35] relative">
              <p>
                I was skeptical as I already tried plain GPT for content,
                which required so much massaging and still missed the mark.
              </p>
              <p className="mt-3">
                Rank-Hub managed to give me <span
                className="text-primary-500">content ideas that are actually searched</span> in my niche
                and write full blog posts that <span
                className="text-primary-500">I am actually proud to publish</span> with minimal post-editing.
              </p>
              <div className="text-5xl text-gray-400 invisible md:visible">
                <div
                  className="absolute top-0 left-0 -translate-x-[160%] -translate-y-[60%] font-serif font-bold">&ldquo;</div>
                <div
                  className="absolute bottom-0 right-0 translate-x-[50%] translate-y-full font-serif font-bold">&rdquo;</div>
              </div>
            </div>
            <p className="mt-6 italic text-lg">
              <a className="underline text-primary-500 underline-offset-2" href="https://twitter.com/JFTucci"
                 target="_blank"
                 rel="noreferrer">
                Fernando
              </a> from Kansei
            </p>
          </div>
        </div>
      </div>

      <div className="py-24 overflow-x-hidden text-gray-300 px-8 md:px-24">
        <div className="max-w-[100rem] mx-auto">
          {/*<Review imgeUrl="/testimonial-pictures/ephraim.jpg"/>*/}

          <h2
            className="font-bold text-3xl md:text-5xl text-gray-50 text-center md:leading-[1.2] max-w-[60rem] mx-auto">
            Rank-Hub transforms your SEO labyrinth into a clear path of action
          </h2>

          <div className="flex flex-col md:flex-row gap-16 gap-y-6 items-center mt-12 md:mt-20">
            <div className="md:w-0 md:flex-grow-[2]">
              <h2 className="mt-2 font-bold text-gray-100 text-xl md:text-4xl md:leading-[1.2]">
                Research keyword ideas
              </h2>
              {/* TODO: split test the below two paragraphs */}
              <p className="mt-6 text-lg leading-[140%]">
                Enter a topic / niche and get a list of keywords along with search volume and ranking difficulty.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Research on what topics to cover on your blog has never been easier.
              </p>
              <Button to="/signup" className="mt-8">
                Get {maxTrialCredits} credits for free
                <div className="animate-buttonicon">
                  <ChevronRight />
                </div>
              </Button>
            </div>


            <video loop autoPlay muted
                   className="md:w-0 md:flex-grow-[3] rounded-xl shadow-md">
              <source src="/feature-media/search-analysis.mp4" type="video/mp4" />
            </video>

            {/*
            <img src="/test.png" height=""
                 alt="Screenshot of Rank-Hub"
                 className="md:w-0 md:flex-grow-[3] rounded-xl border border-desat-100" />
*/}
          </div>

          <div
            className="flex flex-col md:flex-row gap-16 gap-y-8 items-center my-16 md:my-40">
            <div className="md:w-0 md:flex-grow-[2] md:order-2">
              <h2 className="mt-2 font-bold text-gray-100 text-xl md:text-4xl md:leading-[1.2]">
                Topic clusters based on actual live data
              </h2>
              <p className="mt-6 text-lg leading-[140%]">
                Create a tight-knit cluster of posts that increase your authority in that topic in the eyes of search
                engines.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Rank-Hub can create a topic cluster with live SERP data (results, people also ask, related questions
                etc.)
                to present you the articles that Google wants you to write about.
              </p>
              <Button to="/signup" className="mt-8">
                Get {maxTrialCredits} credits for free
                <div className="animate-buttonicon">
                  <ChevronRight />
                </div>
              </Button>
            </div>
            <video loop autoPlay muted
                   className="md:w-0 md:flex-grow-[3] rounded-xl shadow-md">
              <source src="/feature-media/topic-clusters.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col md:flex-row gap-16 gap-y-6 items-center mt-16">
            <div className="md:w-0 md:flex-grow-[2]">
              <h2 className="mt-2 font-bold text-gray-100 text-xl md:text-4xl md:leading-[1.2]">
                Never start from a blank page again
              </h2>
              <p className="mt-6 text-lg leading-[140%]">
                It's hard to get a post started when you sit in front of a blank page.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Rank-Hub pulls in top-ranking posts for your search query from Google,
                analyzes their content and drafts a multi-level outline based on that research.
              </p>
              <Button to="/signup" className="mt-8">
                Get {maxTrialCredits} credits for free
                <div className="animate-buttonicon">
                  <ChevronRight />
                </div>
              </Button>
            </div>

            <video loop autoPlay muted
                   className="md:w-0 md:flex-grow-[3] rounded-xl shadow-md">
              <source src="/feature-media/outline-drafter.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="flex flex-col md:flex-row gap-16 gap-y-6 items-center mt-16 my-16 md:my-40">
            <video loop autoPlay muted className="md:w-0 md:flex-grow-[3] rounded-xl shadow-md">
              <source src="/feature-media/ai-writer.mp4" type="video/mp4" />
            </video>
            <div className="md:w-0 md:flex-grow-[2]">
              <h2 className="mt-2 font-bold text-gray-100 text-xl md:text-4xl md:leading-[1.2]">
                Rank-Hub has the best AI writer for blog posts, period
              </h2>
              <p className="mt-6 text-lg leading-[140%]">
                Enjoy unparalleled adherence to your content instructions by generating the article in sections,
                guided by a pre-filled instructions prompt you can edit. <i>The</i> best way to let AI write content.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Rank-Hub AI uses a vector database of competitor content that allows the AI to query and
                cross-reference before writing, virtually eliminating content hallucinations.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Here's an example of <a href="https://rank-hub.com/blog/example-article-for-ai-generated-content/"
                                        target="_blank" className="underline text-primary-500" rel="noreferrer">
                an article written with Rank-Hub AI</a>, no editing.
              </p>
              {/*  TODO: add reference article link*/}
              <Button to="/signup" className="mt-8">
                Get {maxTrialCredits} credits for free
                <div className="animate-buttonicon">
                  <ChevronRight />
                </div>
              </Button>
            </div>

          </div>

          <div className="flex flex-col md:flex-row gap-16 gap-y-6 items-center mt-16 my-16 md:my-40">
            <div className="md:w-0 md:flex-grow-[2]">
              <h2 className="mt-2 font-bold text-gray-100 text-xl md:text-4xl md:leading-[1.2]">
                Not sure what specific steps to take?
              </h2>
              <p className="mt-6 text-lg leading-[140%]">
                Our integration with your Google Search Console data can generate the
                lowest effort tasks that get you the most impact, every week.
              </p>
              <p className="mt-3 text-lg leading-[140%]">
                Stay on track with clear next steps, knowing what to tackle for SEO gains every week.
              </p>
              {/*  TODO: add reference article link*/}
              <Button to="/signup" className="mt-8">
                Setup your weekly tasks for free
              </Button>
            </div>

            <img src="/feature-media/weekly-tasks.png" alt="Weekly Tasks"
                 className="shadow-md md:w-0 md:flex-grow-[3] rounded-xl" />
          </div>
        </div>
      </div>
      {/* Review Ephraim */}
      <div className="bg-gray-800 px-8">
        <div
          className="flex flex-col md:flex-row items-center max-w-[70rem] w-full mx-auto py-16 md:py-24 gap-10 md:gap-24">
          <img src="/testimonial-pictures/ephraim.jpg" alt="Ephraim from TLGhost" width="400" height="400"
               className="rounded-full w-32 h-32" />
          <div className="">
            <div className="text-xl md:text-3xl text-gray-200 font-semibold tracking-wide leading-[1.35] relative">
              Used to take me <span className="text-primary-500">almost an hour</span> to manually read through several
              articles to come up
              with a banger document outline for 1 article. Now it's <span className="text-primary-500">done for me in minutes</span>.
              Gamechanger.
              <div className="text-8xl text-gray-400 invisible md:visible">
                <div
                  className="absolute top-0 left-0 -translate-x-[160%] -translate-y-[60%] font-serif font-bold">&ldquo;</div>
                <div
                  className="absolute bottom-0 right-0 translate-x-[50%] translate-y-full font-serif font-bold">&rdquo;</div>
              </div>
            </div>
            <p className="mt-6 text-lg italic">
              <a className="underline text-primary-500 underline-offset-2" href="https://twitter.com/ephraimyx"
                 target="_blank"
                 rel="noreferrer">
                Ephraim
              </a> from TLGhost
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-gray-300 relative pt-24 md:pb-16 pb-4" id="pricing">
        <div className="absolute inset-0 hero-bg" />
        <div className="py-8 md:py-16 px-8 md:px-24 relative">
          <div className="max-w-[90rem] mx-auto">
            <h1 className="text-3xl md:text-5xl font-semibold text-gray-100 text-center">
              Rank-Hub can help you from your first blog post up to <span className="font-black text-white">
              10,000+ words </span>every week
            </h1>
            {/*
            <h2 className="mt-2 md:mt-4 text-center text-gray-200 text-lg md:text-2xl font-semibold">
            </h2>
*/}

            <div className="flex items-start justify-center flex-col md:flex-row mt-8 gap-8 md:mt-16 text-gray-100">
              {plans.slice().reverse().map(plan =>
                <div
                  className="w-full md:w-96 bg-gradient-to-bl from-gray-700/20 to-gray-800/40 hover:scale-105 text-gray-200 backdrop-blur-[0.15rem] py-8 px-10 rounded-md duration-700 shadow-[inset_0_0_4px_0_#fffa,inset_0_0_1.6rem_0_#ffffff3a]">
                  {/*
                      <h2 className="font-semibold text-gray-200 text-xl">{plan.name}</h2>
                      <div className="mt-3 flex justify-between items-end">
                        <h2 className="font-bold text-gray-50 text-3xl inline line-through">${plan.altPrice}</h2>
                        <div>
                          <h2 className="font-bold text-xl inline">${plan.price}</h2>
                          <span className="text-gray-300"> / month </span>
                        </div>
                      </div>
*/}
                  <div className="mt-3 flex justify-between items-center">
                    <h2 className="font-black text-white text-3xl">{plan.name}</h2>
                    <div>
                      <h2 className="text-gray-200 text-xl font-semibold inline align-top">
                        <span className="text-gray-400 mr-0.5 text-base align-top">
                          $
                        </span>
                        {plan.price}
                      </h2>
                    </div>
                  </div>
                  <span className="text-gray-400"> monthly </span>

                  <p className="mt-4 text-gray-50 font-semibold text-lg">
                    {plan.tagline}
                  </p>
                  <div className="flex flex-col gap-2 mt-4">
                    <div className="flex items-center gap-4">
                      <Check size="18" />
                      <span>
                    <b className="text-gray-50">{plan.credits} Rank-Hub {" "}</b>
                    <CreditsTooltip />
                  </span>
                    </div>
                    <div className="flex items-center gap-4">
                      <Check size="18" />
                      <p>
                        <b className="text-gray-50">${plan.perCredit}</b> / Credit
                      </p>
                    </div>
                    {/*
                    <div className="flex items-center gap-4">
                      <Check size="18" />
                      <p>
                        <b className="text-gray-50">5</b> Weekly Tasks
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <Check size="18" />
                      <p>
                        <b className="text-gray-50">{plan.seats}</b> Seat{plan.seats !== 1 && "s"}
                      </p>
                    </div>
*/}
                  </div>
                  <hr className="mt-6 w-3/4 mx-auto border-gray-500" />
                  <div className="pt-6 flex justify-center">
                    <Button to="/signup" variant={"default"}>
                      Get started for free
                    </Button>
                  </div>
                  {/*
                  <a href="/signup"
                     className="block text-center mt-4 px-3 py-2 bg-primary-600 text-primary-50 hover:scale-105 transition-all font-bold rounded w-full">
                    Get started for free
                  </a>
*/}
                  <p className="mt-2 text-center text-gray-300 text-xs">
                    {maxTrialCredits} credit free trial &middot; no credit card required
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="pt-8 md:pt-16" id="faq">
            <div className="max-w-6xl mx-auto">
              <h2 className="font-bold text-white text-4xl text-center mt-16">
                Frequently Asked Questions
              </h2>
              <div
                className="max-w-prose mx-auto flex flex-col gap-4 mt-16 text-gray-300">
                <FaqToggle q="How does Rank-Hub work?">
                  <p className="mt-3">
                    Think of Rank-Hub as your personal SEO assistant.
                    It taps into Google’s latest data to pinpoint exactly what your site needs
                    to climb up the search rankings.
                  </p>
                  <p className="mt-3">
                    From identifying the right topics to crafting posts that
                    speak Google’s language, Rank-Hub helps you nail
                    every SEO task with precision.
                  </p>
                  <p className="mt-3">
                    You get clear-cut, step-by-step tasks that translates into
                    real, quality content - and it all happens in a fraction
                    of the time you'd usually spend
                  </p>
                </FaqToggle>
                <FaqToggle q="What are Rank-Hub Credits?">
                  <p className="mt-3">
                    Our credit system is designed to offer you flexibility while
                    aligning with our costs.
                  </p>
                  <p className="mt-3">
                    This way, you can save by only
                    using credits for features you need most,
                    without overspending on those you use less
                  </p>
                </FaqToggle>
                <FaqToggle q="Who is Rank-Hub for?">
                  <p className="mt-3">
                    Rank-Hub is ideal for business owners who want to grow their
                    traffic and visibility without getting bogged down in
                    the complexities of SEO and SEO specialists.
                  </p>
                  <p className="mt-3">
                    It's especially useful for those looking get clear guidance
                    on SEO strategy and save time while doing so.
                  </p>
                </FaqToggle>
                {/*<div>
                  <h3 className="font-bold text-gray-50 text-2xl">
                    What is your refund/cancellation policy?
                  </h3>
                  <p className="mt-3">
                    We are offering a 5 day refund policy. Send an email
                  </p>
                  <p className="mt-3">
                    You can <a className="underline" href="https://l.rank-hub.com/schedule">book
                    an onboarding call</a> with the founder for free too.
                  </p>
                </div>*/}
                <FaqToggle q="How is this different from ChatGPT or other AI writers?">
                  <p className="mt-3">
                    While ChatGPT and other AI tools excel at generating coherent
                    text by predicting the next word in a sequence,
                    successful SEO requires a broader strategy than just text creation
                  </p>
                  <p className="mt-3">
                    Rank-Hub is engineered to guide you through the SEO process,
                    providing specific tasks that extend far beyond writing.
                  </p>
                  <p className="mt-3">
                    What sets Rank-Hub's AI writer apart is its foundational design
                    that leverages your unique context and expertise.
                    It's crafted to collaborate with you, rather than just churning out text.
                  </p>
                </FaqToggle>
                <FaqToggle q="Does Google penalize AI content?">
                  <p className="mt-3">
                    Google stated that they don't penalize AI content. They
                    explicitly allow using AI in the content creation process
                    if it helps generate helpful content.
                  </p>
                  <p className="mt-3">
                    With Rank-Hub, you'll create content that's in line with
                    Google's policies and outranks many non-AI articles.
                  </p>
                </FaqToggle>
                <FaqToggle q="Does AI really produce quality text?">
                  <p className="mt-3">
                    The right AI models can indeed produce very high quality text.
                    Take a look at <Link className="underline" to="/blog/example-article-for-ai-generated-content/">this
                    unedited sample</Link>.
                  </p>
                </FaqToggle>
                <FaqToggle q="Rank-Hub is too expensive">
                  <p className="mt-3">
                    On the contrary, it'll save you countless of hours every
                    month and increase the odds of growing your traffic significantly.
                  </p>
                  <p className="mt-3">
                    One hour of your time probably is worth more than the subscription price, isn't it?
                  </p>
                </FaqToggle>
                <FaqToggle q="Is Rank-Hub difficult to use?">
                  <p className="mt-3">
                    If you know how to navigate Netflix, you know how to navigate Rank-Hub.
                  </p>
                  <p className="mt-3">
                    It's web-based, meaning you don't even need to install anything.
                    Sign up for free and see for yourself.
                  </p>
                </FaqToggle>
                <FaqToggle q="Why shouldn't I choose more articles over quality articles?">
                  <p className="mt-3">
                    Many AI SEO tools allow you to publish an ungodly amount of long form
                    articles. It's likely you'll ask yourself if that isn't the better
                    option for more traffic.
                  </p>
                  <p className="mt-3">
                    The truth is, those tools often create long form articles that are
                    made up of mostly fluff and they are certainly not made for human readers.
                  </p>
                  <p className="mt-3">
                    They might help you get a tad more traffic. Rank-Hub will help
                    you grow your traffic reliably and in a way that you can leverage
                    that traffic boost into conversions. Low quality articles
                    that make very human reader hit the back button won't cut it.
                  </p>

                </FaqToggle>
                <FaqToggle q="What happens after I sign up for free?">
                  <p className="mt-3">
                    The free trial signup will grant you {maxTrialCredits} credits without
                    any commitment. You don't have to enter a payment method
                    and you don't have to cancel anything if you don't like it.
                  </p>
                  <p className="mt-3">
                    You'll be able to do research for the topics that are relevant
                    to your site, find keywords, search queries, source article
                    ideas and let AI write outlines.
                  </p>
                </FaqToggle>
                <FaqToggle q="How do I cancel after subscribing?">
                  <p className="mt-3">
                    If you pick up a paid Rank-Hub plan, you'll be able to
                    cancel at any time, preventing any further costs.
                  </p>
                  <p className="mt-3">
                    You can do this in your billing menu in Rank-Hub.
                  </p>
                </FaqToggle>
                <FaqToggle q="Is Rank-Hub compatible with WordPress, Webflow, Ghost...?">
                  <p className="mt-3">
                    Rank-hub will let you export your outlines or finished articles
                    by copying them into your clipboard.
                  </p>
                  <p className="mt-3">
                    If pasted into a rich text field (like the editor of CMS software
                    like WordPress), it will be formatted correctly automatically,
                    making it compatible with virtually any CMS.
                  </p>
                </FaqToggle>
                {/*
                <FaqToggle q="Can I purchase more Rank-Hub credits?">
                  <p className="mt-3">
                    At the moment you can't self-serve purchase any more credits
                    (apart from switching to the next-higher tier).
                  </p>
                  <p className="mt-3">
                    If you need to, though, contact us
                    via <Link className="underline" to="/legal/imprint">the contact form</Link> and
                    we'll make it work for you.
                  </p>
                </FaqToggle>
*/}

                {/*<div>
                  <h3 className="font-bold text-gray-50 text-2xl">
                    What languages are supported
                  </h3>
                  <p className="mt-3">
                    We support
                  </p>
                </div>*/}
                <FaqToggle q="Do you provide any support?">
                  <p className="mt-3">
                    Yes, support via email is included with every
                    plan, even the free trial.
                  </p>
                  <p className="mt-3">
                    Please use <Link className="underline" to="/legal/imprint">the contact form</Link> and
                    we'll be in touch with you shortly.
                  </p>
                </FaqToggle>
              </div>
              <div className="mt-16 text-center">
                <Button to={user ? "/app" : "/signup"}>
                  {user ? "My Dashboard" : `Get ${maxTrialCredits} credits for free`}
                </Button>
                {!user &&
                  <p className="text-gray-200 italic text-sm mt-2 mb-6">
                    free trial &middot; no credit card required
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export const CreditsTooltip = () => <Tooltip parent={
  <span className="underline inline-flex items-center gap-1 cursor-pointer">
                         Credits <HelpCircle className="inline" size="18" />
                      </span>
}>
  <img src="/credit-explainer.webp" className="rounded-md" alt="credit screenshot" height="220"
       width="351" />
  <p className="mt-3">Outlining a post based on competitor research costs one credit, other actions such as the initial
    search analysis or topic clustering cost 2</p>
  <p className="mt-3">Credits are refilled each renewal / month</p>
</Tooltip>;

export default Index;
